<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col">
            <label for="dateDebut">Date début</label>
            <datepicker  format="d MMMM yyyy" v-model="dateDebut"></datepicker>
          </div>
          <div class="vx-col">
            <label for="dateFin">Date fin</label>
            <datepicker  format="d MMMM yyyy" v-model="dateFin"></datepicker>
          </div>
          <div class="vx-col">
            <label for="idOfficine">Officine</label>
            <vs-select autocomplete class="w-full sm:order-normal order-3" v-model="idOfficine">
            <vs-select-item  class="w-full" value="toutes" text="Toutes les officines" />
              <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
            </vs-select>
          </div>
          <div class="vx-col">
            <label for="dateFin">Assurance</label>
            <vs-select autocomplete class="w-full sm:order-normal order-3" v-model="assurance">
              <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.Nom_Complet" v-for="(item,index) in assuranceData" />
            </vs-select>
          </div>
          <div class="vx-col mt-5">
            <vs-button ref="loadableButton" id="button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-search" @click="findVenteNonTraiterByAssurance()">VALIDER</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mt-5">
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="3508"
        :filename="NumVente ? NumVente : 'Facture-client'"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="95%"

        ref="html2Pdf"
      >
        <section class="text-xs" style="padding-left: 55px;padding-top: 10px;font-size: 10px;" slot="pdf-content">
          <br>
          <br>
          <br>
          <br>
          <br>
          <div class="vx-row" style="margin-top: 20px">
            <div class="vx-col w-1/2">

            </div>
            <div class="vx-col w-1/2 text-center text-xs font-bold">
              {{lieu_edition}} {{(DateVente ? DateVente : null) | moment("calendar", "July 10 2011") }}
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col w-1/2">
              <span class="font-bold text-xs">{{getAssuranceName(IdAssurance ? IdAssurance : null) ? getAssuranceName(IdAssurance ? IdAssurance : null).Nom_Complet : ''}}</span>
            </div>
            <div class="vx-col w-1/2 text-center">
              <span class="font-bold text-xs">{{getSocieteById(IdSociete) ? getSocieteById(IdSociete).NomSociete : ''}}</span>
            </div>
          </div>
          <div class="vx-row p-5">
            <div class="vx-col w-full text-center">
              <span class="text-xs">DETAIL FACTURE</span> <br>
              PERIODE DU  {{dateDebut | moment("calendar", "July 10 2011") }} AU {{dateFin | moment("calendar", "July 10 2011") }}
            </div>
            <table class="w-full mt-5 text-xs" cellpadding="0" cellspacing="0">
              <thead>
                <template>
                  <tr>
                    <th class="customth text-center text-xs" style="border-bottom-width: 0px; padding: 3px;" colspan="12">
                      CLIENT
                    </th>
                  </tr>
                  <tr>
                    <td class="customtd text-xs" style="border-bottom-width: 0px; border-right-width: 0px;"  colspan="1">
                      <span class="font-bold" style="font-size: 8px;">N°</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">DATE</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">MATRICULE</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 8px;">ENTREPRISE</span>
                    </td>
                    <td  class="customtd text-center" style="border-bottom-width: 0px;" colspan="6">
                      <span class="font-bold" style="font-size: 8px;">PATIENT</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-semibold" style="font-size: 8px;">{{NumVente ? NumVente : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-semibold" style="font-size: 8px;">{{(DateVente ? DateVente : null)  | moment("calendar", "July 10 2011")}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-semibold" style="font-size: 8px;">{{matricule ? matricule : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                      <span class="font-semibold" style="font-size: 8px;">{{getSocieteById(IdSociete) ? getSocieteById(IdSociete).NomSociete : ''}}</span>
                    </td>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="6">
                      <span class="font-semibold" style="font-size: 8px;">{{Patient ? Patient : ''}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; padding:10px;" colspan="12">

                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="12">
                      <span class="font-bold" style="font-size: 8px;">VERRES OPTIQUES</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-bold" style="font-size: 8px;">FOURNISSEUR: {{getFournisseurVerreById(four_verre) ? getFournisseurVerreById(four_verre).Fournisseur : ''}} </span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="3">
                      <span class="font-semibold" style="font-size: 8px;">NOM COMMERCIAL: {{NomCommercialVerre ? NomCommercialVerre : ''}} </span>
                    </td>
                    <td class="customtd text-center" style="border-bottom-width: 0px; border-left-width: 0px;" colspan="4">

                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="12">
                      <span class="font-semibold" style="font-size: 8px;">PRESCRIPTION</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-width: 0px;" colspan="1">

                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">SPHERE </span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">CYLINDRE </span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">AXE </span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">ADDITION </span>
                    </td>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="4">
                      <span class="font-semibold" style="font-size: 8px;">PRIX HT</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">OD</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">{{SphVD ? SphVD : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">{{CylVD ? CylVD : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">{{AxeVD ? AxeVD : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">{{AddVD ? AddVD : ''}}</span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="4">
                      <span class="font-semibold" style="font-size: 8px;">{{PrixVerD1 ? moneyFormatter(PrixVerD1) : ''}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">OG</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">{{SphVG ? SphVG : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">{{CylVG ? CylVG : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">{{AxeVG ? AxeVG : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 8px;">{{AddVG ? AddVG : ''}}</span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="4">
                      <span class="font-semibold" style="font-size: 8px;">{{PrixVerG1 ? moneyFormatter(PrixVerG1) : ''}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; padding:10px;" colspan="12">

                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-bold" style="font-size: 8px;">TRAITEMENT DES VERRES </span>
                    </td>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="4">
                      <span class="font-semibold" style="font-size: 8px;">PRIX HT</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-semibold" style="font-size: 8px;">PHOTOGRAY</span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                      <span class="font-semibold">
                        <template v-if="prixPhotogray === 0">
                        -
                      </template>
                      <template v-else style="font-size: 8px;">
                        {{moneyFormatter(prixPhotogray)}}
                      </template>
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td class="customtd text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-semibold" style="font-size: 8px;">ANTI REFLET</span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                      <span class="font-semibold">
                        <template v-if="prixAntiReflet === 0">
                          -
                        </template>
                        <template v-else style="font-size: 8px;">
                          {{moneyFormatter(prixAntiReflet)}}
                        </template>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-semibold" style="font-size: 8px;">ANTI RAYURE</span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                      <span class="font-semibold">
                        <template v-if="prixAntiRayure === 0">
                          -
                        </template>
                        <template v-else style="font-size: 8px;">
                          {{moneyFormatter(prixAntiRayure)}}
                        </template>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-semibold" style="font-size: 8px;">AUTRE</span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                      <span class="font-semibold">
                        <template v-if="prixAutreTraitement === 0">
                          -
                        </template>
                        <template v-else style="font-size: 8px;">
                          {{moneyFormatter(prixAutreTraitement)}}
                        </template>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                      <span class="font-bold" style="font-size: 8px;">MONTANT VERRES </span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px; border-left-width: 0px;" colspan="5">
                      <span class="font-semibold" style="font-size: 8px;">{{moneyFormatter(totalVerreProposition((PrixVerD1 ? PrixVerD1 : 0), (PrixVerG1 ? PrixVerG1 : 0)))}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="12">
                      <span class="font-bold" style="font-size: 8px;">MONTURE</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-bold" style="font-size: 8px;">FOURNISSEUR: {{getFournisseurMontureById(four_monture) ? getFournisseurMontureById(four_monture).Nom : null}} </span>
                    </td>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="5">
                      <span class="font-semibold" style="font-size: 8px;">PRIX HT</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-left" style="border-right-width: 0px;" colspan="5">

                      <span class="font-semibold" style="font-size: 8px;">MARQUE: {{monture ? (getMarqueName(monture.IdMarque) ? getMarqueName(monture.IdMarque).Libelle_marq : '') : ''}}</span><br>
                      <span class="font-semibold" style="font-size: 8px;">REFERENCE: {{monture ? monture.Etiquette : ''}} </span><br>
                      <span class="font-semibold" style="font-size: 8px;">COULEUR: {{monture ? monture.Couleur : ''}}</span><br>
                      <span class="font-semibold" style="font-size: 8px;">TAILLE: {{monture ? `${monture.Taille[0].Longeur}/${monture.Taille[0].Hauteur}/${monture.Taille[0].Nez}` : ''}}</span><br>
                    </td>
                    <td class="customtd text-right" colspan="5">
                      <span class="font-semibold" style="font-size: 8px;">{{moneyFormatter((PrixMont1 ? PrixMont1 : 0))}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="5">

                    </td>
                    <td class="customtd text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 8px;">TOTAL HT: </span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 8px;">{{moneyFormatter(totalNet((PrixMont1 ? PrixMont1 : 0), (PrixVerG1 ? PrixVerG1 : 0), (PrixVerD1 ? PrixVerD1 : 0), (RemiseCom ? RemiseCom : 0)))}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;border-left-width: 0px;"  colspan="5">

                    </td>
                    <td class="customtd text-left" style="border-bottom-width: 0px; border-left-width: 0px;border-right-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 8px;">TICKET MODERATEUR </span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 8px;">{{moneyFormatter(calculParClient((PrixMont1 ? PrixMont1 : 0), (PrixVerG1 ? PrixVerG1 : 0), (PrixVerD1 ? PrixVerD1 : 0), (RemiseCom ? RemiseCom : 0), (plafon ? plafon : 0), (TickForf ? TickForf : 0)))}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                    </td>
                  </tr>

                  <tr>
                    <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="5">

                    </td>
                    <td class="customtd text-left" style="border-bottom-width: 0px;border-right-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 8px;">PART ASSURANCE TTC </span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 8px;">{{moneyFormatter(calculParAssurance((PrixMont1 ? PrixMont1 : 0), (PrixVerG1 ? PrixVerG1 : 0), (PrixVerD1 ? PrixVerD1 : 0), (RemiseCom ? RemiseCom : 0), (plafon ? plafon : 0), (TickForf ? TickForf: 20)))}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="5">

                    </td>
                    <td class="customtd text-left" style="border-bottom-width: 0px;border-left-width: 0px; padding: 10px;border-right-width: 0px;" colspan="4">
                      <span></span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" colspan="12">
                      <span class="font-bold" style="font-size: 8px;">ARRETE LA PRESENTE FACTURE A LA SOMME DE: {{NumberToLetter(calculParAssurance((PrixMont1 ? PrixMont1 : 0), (PrixVerG1 ? PrixVerG1 : 0), (PrixVerD1 ? PrixVerD1 : 0), (RemiseCom ? RemiseCom : 0), (plafon ? plafon : 0), (TickForf ? TickForf: 20)))}}  FRANCS CFA</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-right" colspan="12" style="font-size: 10px; border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;">
                      <span class="font-bold">L'OPTICIEN DIRECTEUR</span>
                    </td>
                  </tr>
                </template>
              </thead>
            </table>
          </div>
        </section>
      </vue-html2pdf>
      <vs-popup fullscreen title="PRESCRIPTION" :active.sync="popupPrescription">
        <div class="vx-row">
          <div class="vx-col mt-8">
            <span class="font-black">OD</span>
            <br /><br />
            <feather-icon
            icon="ArrowDownIcon"
            class="cursor-pointer"
            @click="CopyAllPrescriptionODToOG()" />
          </div>
          <div class="vx-col  text-center">
            <vs-input class="w-full" size="small" type="text" label="SPH"  name="SphVD" :value="SphVD"  @change.native="FormateSPHOD($event.target.value)" />
            <br>
            <feather-icon
            icon="ArrowDownIcon"
            class="cursor-pointer"
            @click="CopySphVDToSphVG()" />
          </div>
          <div class="vx-col  text-center">
            <vs-input class="w-full" size="small" type="text" label="CYL"  name="CylVD" :value="CylVD"  @change.native="FormateCYLOD($event.target.value)" />
            <br>
            <feather-icon
            icon="ArrowDownIcon"
            class="cursor-pointer"
            @click="CopyCylVDToCylVG()" />
          </div>
          <div class="vx-col  text-center">
            <vs-input class="w-full" size="small" type="text" label="AXE"  name="AxeVD" :value="AxeVD" @change.native="AxeVD=$event.target.value" />
            <br>
            <feather-icon
            icon="ArrowDownIcon"
            class="cursor-pointer"
            @click="CopyAxeVDToAxeVG()" />
          </div>
          <div class="vx-col text-center">
            <vs-input class="w-full" size="small" type="text" label="ADDITION"  name="AddVD" :value="AddVD" @change.native="FormateAddVD($event.target.value)" />
            <br>
            <feather-icon
            icon="ArrowDownIcon"
            class="cursor-pointer"
            @click="CopyAddVDToAddVG()" />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col mt-8">
            <span class="font-black">OG</span>
          </div>
          <div class="vx-col text-center">
            <vs-input class="w-full" size="small" type="text" label="SPH"  name="SphVG" :value="SphVG" @change.native="FormateSPHOG($event.target.value)" />
          </div>
          <div class="vx-col text-center">
            <vs-input class="w-full" size="small" type="text" label="CYL"  name="CylVG" :value="CylVG"  @change.native="FormateCYLOG($event.target.value)" />
          </div>
          <div class="vx-col text-center">
            <vs-input class="w-full" size="small" type="text" label="AXE"  name="AxeVG" :value="AxeVG" @change.native="AxeVG=$event.target.value" />
          </div>
          <div class="vx-col text-center">
            <vs-input class="w-full" size="small" type="text" label="ADDITION"  name="AddVG" :value="AddVG" @change.native="FormateAddVG($event.target.value)" />
          </div>
        </div>
      </vs-popup>
      <vs-popup title="TRAITEMENT DES VERRES" :active.sync="popupTraitementVerre">
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-input class="w-full mt-4" type="number" min="0" label="PHOTOGRAY"  name="PHOTOGRAY" v-model="prixPhotogray" />
          </div>
          <div class="vx-col w-full">
            <vs-input class="w-full mt-4" type="number" min="0" label="ANTI REFLET"  name="ANTI_REFLET" v-model="prixAntiReflet" />
          </div>
          <div class="vx-col w-full">
            <vs-input class="w-full mt-4" type="number" min="0" label="ANTI RAYURE"  name="ANTI_RAYURE" v-model="prixAntiRayure" />
          </div>
          <div class="vx-col w-full">
            <vs-input class="w-full mt-4" type="number" min="0" label="AUTRE TRAITEMENT"  name="AUTRE_TRAITEMENT" v-model="prixAutreTraitement" />
          </div>
        </div>
      </vs-popup>
      <vs-popup title="CONSOMMER PLAFON" :active.sync="popupConsommerPlafon">
        <div class="vx-row">
          <div class="vx-col w-full">
            <span>Ajuster les prix:</span>
          </div>
          <div class="vx-col md:w-1/2 mt-5">
            <vs-checkbox label="Prix monture" v-model="ajusterPrixMonture" class="w-full" > Prix monture </vs-checkbox>
          </div>
          <div class="vx-col md:w-1/2 mt-5">
            <vs-checkbox label="Prix verres" v-model="ajusterPrixVerre" class="w-full" > Prix verres </vs-checkbox>
          </div>
          <div class="vx-col w-full mt-5">
            <vs-select label="Majoration part client" autocomplete class="selectExample w-full select-large mt-4" v-model="partMajorerClient">
              <vs-select-item :key="index" :value="item.taux" :text="`${item.valeur}`" v-for="(item,index) in tauxMajorations" />
            </vs-select>
          </div>
        </div>
        <div class="vx-row mt-5">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="ConsommerPlafon()">VALIDER</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="popupConsommerPlafon=false">ANNULER</vs-button>
            </div>
          </div>
      </vs-popup>
      <vs-popup fullscreen title="Traitement vente" :active.sync="popupTraitement">
        <div class="vx-row p-10">
          <div class="vx-col md:w-2/3">
            <div class="vx-row" style="margin-top: 0px">
              <div class="vx-col w-1/2">

              </div>
              <div class="vx-col w-1/2 text-center">
                {{lieu_edition}} {{(DateVente ? DateVente : null) | moment("calendar", "July 10 2011") }}
              </div>
            </div>
            <div class="vx-row mt-5">
              <div class="vx-col w-1/2">
                <span class="font-bold">{{getAssuranceName(IdAssurance ? IdAssurance : null) ? getAssuranceName(IdAssurance ? IdAssurance : null).Nom_Complet : ''}}</span>
              </div>
              <div class="vx-col w-1/2 text-center">
                <span class="font-bold">{{getSocieteById(IdSociete) ? getSocieteById(IdSociete).NomSociete : ''}}</span>
              </div>
            </div>
            <div class="vx-row p-5">
              <div class="vx-col w-full text-center">
                <span>DETAIL FACTURE</span> <br>
                PERIODE DU  {{dateDebut | moment("calendar", "July 10 2011") }} AU {{dateFin | moment("calendar", "July 10 2011") }}
              </div>
              <table class="w-full mt-5" cellpadding="0" cellspacing="0">
                <thead>
                  <template>
                    <tr>
                      <th class="customth text-center" style="border-bottom-width: 0px; padding: 3px;" colspan="12">
                        CLIENT
                      </th>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;"  colspan="1">
                        <span class="font-bold">N°</span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-bold">DATE</span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-bold">MATRICULE</span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-bold">ENTREPRISE</span>
                      </td>
                      <td  class="customtd text-center" style="border-bottom-width: 0px;" colspan="5">
                        <span class="font-bold">PATIENT</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-semibold">{{NumVente ? NumVente : ''}}</span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-semibold">{{(DateVente ? DateVente : null)  | moment("calendar", "July 10 2011")}}</span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-semibold">{{matricule ? matricule : ''}}</span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-semibold">{{getSocieteById(IdSociete) ? getSocieteById(IdSociete).NomSociete : ''}}</span>
                      </td>
                      <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="5">
                        <span class="font-semibold">{{Patient ? Patient : ''}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; padding:10px;" colspan="12">

                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="12">
                        <span class="font-bold">VERRES OPTIQUES</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-bold">FOURNISSEUR: {{getFournisseurVerreById(four_verre) ? getFournisseurVerreById(four_verre).Fournisseur : ''}} </span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="3">
                        <span class="font-semibold">NOM COMMERCIAL: {{NomCommercialVerre ? NomCommercialVerre : ''}} </span>
                      </td>
                      <td class="customtd text-center" style="border-bottom-width: 0px; border-left-width: 0px;" colspan="4">

                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="12">
                        <span class="font-semibold cursor-pointer hover:underline" @click.stop="popupPrescription=true">PRESCRIPTION <feather-icon title="Prescriptions" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" /></span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-width: 0px;" colspan="1">

                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">SPHERE </span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">CYLINDRE </span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">AXE </span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">ADDITION </span>
                      </td>
                      <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="4">
                        <span class="font-semibold">PRIX HT</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">OEIL DROIT </span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{SphVD ? SphVD : ''}}</span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{CylVD ? CylVD : ''}}</span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{AxeVD ? AxeVD : ''}}</span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{AddVD ? AddVD : ''}}</span>
                      </td>
                      <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="4">
                        <span class="font-semibold">{{PrixVerD1 ? moneyFormatter(PrixVerD1) : ''}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">OEIL GAUCHE </span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{SphVG ? SphVG : ''}}</span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{CylVG ? CylVG : ''}}</span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{AxeVG ? AxeVG : ''}}</span>
                      </td>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{AddVG ? AddVG : ''}}</span>
                      </td>
                      <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="4">
                        <span class="font-semibold">{{PrixVerG1 ? moneyFormatter(PrixVerG1) : ''}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; padding:10px;" colspan="12">

                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-bold cursor-pointer hover:underline" @click.stop="popupTraitementVerre=true">TRAITEMENT DES VERRES <feather-icon title="Traitement des verres" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" /></span>
                      </td>
                      <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="4">
                        <span class="font-semibold">PRIX HT</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-semibold">PHOTOGRAY</span>
                      </td>
                      <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                        <span class="font-semibold">
                          <template v-if="prixPhotogray === 0">
                          -
                        </template>
                        <template v-else>
                          {{moneyFormatter(prixPhotogray)}}
                        </template>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td class="customtd text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-semibold">ANTI REFLET</span>
                      </td>
                      <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                        <span class="font-semibold">
                          <template v-if="prixAntiReflet === 0">
                            -
                          </template>
                          <template v-else>
                            {{moneyFormatter(prixAntiReflet)}}
                          </template>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-semibold">ANTI RAYURE</span>
                      </td>
                      <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                        <span class="font-semibold">
                          <template v-if="prixAntiRayure === 0">
                            -
                          </template>
                          <template v-else>
                            {{moneyFormatter(prixAntiRayure)}}
                          </template>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-semibold">AUTRE</span>
                      </td>
                      <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                        <span class="font-semibold">
                          <template v-if="prixAutreTraitement === 0">
                            -
                          </template>
                          <template v-else>
                            {{moneyFormatter(prixAutreTraitement)}}
                          </template>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                        <span class="font-bold">MONTANT VERRES </span>
                      </td>
                      <td class="customtd text-right" style="border-bottom-width: 0px; border-left-width: 0px;" colspan="5">
                        <span class="font-semibold">{{moneyFormatter(totalVerreProposition((PrixVerD1 ? PrixVerD1 : 0), (PrixVerG1 ? PrixVerG1 : 0)))}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="12">
                        <span class="font-bold">MONTURE</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-bold">FOURNISSEUR: {{getFournisseurMontureById(four_monture) ? getFournisseurMontureById(four_monture).Nom : null}} </span>
                      </td>
                      <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="5">
                        <span class="font-semibold">PRIX HT</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-left" style="border-right-width: 0px;" colspan="5">

                        <span class="font-semibold">MARQUE: {{monture ? (getMarqueName(monture.IdMarque) ? getMarqueName(monture.IdMarque).Libelle_marq : '') : ''}}</span><br>
                        <span class="font-semibold">REFERENCE: {{monture ? monture.Etiquette : ''}} </span><br>
                        <span class="font-semibold">COULEUR: {{monture ? monture.Couleur : ''}}</span><br>
                        <span class="font-semibold">TAILLE: {{monture ? `${monture.Taille[0].Longeur}/${monture.Taille[0].Hauteur}/${monture.Taille[0].Nez}` : ''}}</span><br>
                      </td>
                      <td class="customtd text-right" colspan="5">
                        <span class="font-semibold">{{moneyFormatter((PrixMont1 ? PrixMont1 : 0))}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="5">

                      </td>
                      <td class="customtd text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-bold">TOTAL HT: </span>
                      </td>
                      <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-bold">{{moneyFormatter(totalNet((PrixMont1 ? PrixMont1 : 0), (PrixVerG1 ? PrixVerG1 : 0), (PrixVerD1 ? PrixVerD1 : 0), (RemiseCom ? RemiseCom : 0)))}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;border-left-width: 0px;"  colspan="5">

                      </td>
                      <td class="customtd text-left" style="border-bottom-width: 0px; border-left-width: 0px;border-right-width: 0px;" colspan="2">
                        <span class="font-bold">TICKET MODERATEUR </span>
                      </td>
                      <td class="customtd text-right" style="border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;" colspan="2">
                        <span class="font-bold">{{moneyFormatter(calculParClient((PrixMont1 ? PrixMont1 : 0), (PrixVerG1 ? PrixVerG1 : 0), (PrixVerD1 ? PrixVerD1 : 0), (RemiseCom ? RemiseCom : 0), (plafon ? plafon : 0), (TickForf ? TickForf : 0)))}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="5">

                      </td>
                      <td class="customtd text-left" style="border-bottom-width: 0px;border-right-width: 0px;" colspan="2">
                        <span class="font-bold">PART ASSURANCE TTC </span>
                      </td>
                      <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-bold">{{moneyFormatter(calculParAssurance((PrixMont1 ? PrixMont1 : 0), (PrixVerG1 ? PrixVerG1 : 0), (PrixVerD1 ? PrixVerD1 : 0), (RemiseCom ? RemiseCom : 0), (plafon ? plafon : 0), (TickForf ? TickForf: 20)))}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="5">

                      </td>
                      <td class="customtd text-left" style="border-bottom-width: 0px;border-left-width: 0px; padding: 10px;border-right-width: 0px;" colspan="4">
                        <span></span>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;padding: 10px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                      </td>
                    </tr>
                    <tr>
                      <td class="customtd" colspan="12">
                        <span class="font-bold">ARRETE LA PRESENTE FACTURE A LA SOMME DE: {{NumberToLetter(calculParAssurance((PrixMont1 ? PrixMont1 : 0), (PrixVerG1 ? PrixVerG1 : 0), (PrixVerD1 ? PrixVerD1 : 0), (RemiseCom ? RemiseCom : 0), (plafon ? plafon : 0), (TickForf ? TickForf: 20)))}}  FRANCS CFA</span>
                      </td>
                    </tr>
                  </template>
                </thead>
              </table>
            </div>
          </div>
          <div class="vx-col md:w-1/3 p-5" style="background-color: #ebebeb; border-radius: 5px;">
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="vx-row">
                  <div class="vx-col mt-4">
                    <vx-tooltip text="Annuler" position="top">
                      <vs-button color="danger" icon-pack="feather" icon="icon-x-circle" class="w-full" @click="popupTraitement=false"></vs-button>
                    </vx-tooltip>
                  </div>

                  <div class="vx-col mt-4">
                    <vx-tooltip text="Enregistrer" position="top">
                      <vs-button @click="generateReport()" color="primary" class="w-full" icon-pack="feather" icon="icon-printer" ></vs-button>
                    </vx-tooltip>
                  </div>
                  <div class="vx-col mt-4">
                    <vx-tooltip text="Enregistrer" position="top">
                      <vs-button color="primary" icon-pack="feather" icon="icon-save" class="w-full" @click="saveFacture()"></vs-button>
                    </vx-tooltip>
                  </div>
                </div>
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">Lieu d'edition</label>
                <vs-input class="w-full" type="text"  name="lieu_edition" :value="lieu_edition" @change.native="lieu_edition=$event.target.value" />
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">Société</label>
                <vs-select autocomplete class="selectExample w-full select-large" v-model="IdSociete">
                  <vs-select-item :key="index" :value="item._id" :text="`${item.NomSociete}`" v-for="(item,index) in Societes" />
                </vs-select>
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">Matricule</label>
                <vs-input class="w-full" type="text"  name="NumPatient" :value="NumPatient" @change.native="NumPatient=$event.target.value" />
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">Patient</label>
                <vs-input class="w-full" type="text"  name="Patient" :value="Patient" @change.native="Patient=$event.target.value" />
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">Date</label>
                <datepicker  format="d MMMM yyyy" v-model="DateVente"></datepicker>
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">N° facture</label>
                <vs-input class="w-full" type="text"  name="NumFact" :value="NumFact" @change.native="NumFact=$event.target.value" />
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">Fournisseur verre</label>
                <vs-select autocomplete class="selectExample w-full select-large" v-model="four_verre">
                  <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                </vs-select>
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">Nom commercial verre</label>
                <vs-input class="w-full" type="text"  name="NomCommercialVerre" :value="NomCommercialVerre" @change.native="NomCommercialVerre=$event.target.value" />
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">Qte verre droit</label>
                <vs-input class="w-full" type="number" min="0"  name="QteVerD1" :value="QteVerD1" @change.native="QteVerD1=$event.target.value"/>
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">Prix verre droit</label>
                <vs-input class="w-full" type="number" min="0"  name="PrixVerD1" :value="PrixVerD1" @change.native="PrixVerD1=$event.target.value"/>
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">Qte verre gauche</label>
                <vs-input class="w-full" type="number" min="0"  name="QteVerG1" :value="QteVerG1" @change.native="QteVerG1=$event.target.value"/>
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">Prix verre gauche</label>
                <vs-input class="w-full" type="number" min="0"  name="PrixVerG1" :value="PrixVerG1" @change.native="PrixVerG1=$event.target.value"/>
              </div>
              <div class="vx-col w-full mt-4">
                <label class="text-sm">Fournisseur monture</label>
                <vs-select autocomplete class="selectExample w-full select-large" v-model="four_monture">
                  <vs-select-item :key="index" :value="item._id" :text="`${item.Nom}`" v-for="(item,index) in fournisseurs" />
                </vs-select>
              </div>

              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">Qte monture</label>
                <vs-input class="w-full" type="number"  name="QteMonture1" :value="QteMonture1" @change.native="QteMonture1=$event.target.value" />
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="text-sm">Prix monture</label>
                <vs-input class="w-full" type="number"  name="PrixMont1" :value="PrixMont1" @change.native="PrixMont1=$event.target.value" />
              </div>
              <div class="vx-col w-full mt-4">
                <label class="text-sm">Remise</label>
                <vs-input class="w-full" type="number"  name="RemiseCom" v-model="RemiseCom" @change="ConvertRemise" />
              </div>
              <div class="vx-col w-full mt-4">
                <h3>Plafond: {{moneyFormatter(plafon)}}</h3> <span class="cursor-pointer hover:underline color:primary" @click.stop="popupConsommerPlafon=true">Consommer le plafond</span>
              </div>

            </div>
          </div>
        </div>
      </vs-popup>
      <vx-card >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table v-model="selected" noDataText="" pagination max-items="50" stripe search :data="commandesNonTraiter">
              <template slot="header">
              </template>
              <template slot="thead">
                <vs-th sort-key="numero">
                  N°vente
                </vs-th>
                <vs-th>
                  Date vente
                </vs-th>
                <vs-th sort-key="Nom">
                  Client
                </vs-th>
                <vs-th>
                  Contact
                </vs-th>
                <vs-th>
                  Total vente
                </vs-th>
                <vs-th>
                  Remise
                </vs-th>
                <vs-th>
                  Part client
                </vs-th>
                <vs-th>
                  Part assurance
                </vs-th>
                <vs-th>
                  Actions
                </vs-th>

              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td>
                    {{tr.numVente}}
                  </vs-td>
                  <vs-td>
                    {{tr.dateVente | moment("calendar", "July 10 2011")}}
                  </vs-td>
                  <vs-td :data="`${tr.Nom} ${tr.Prenoms}`">
                    {{tr.Nom}} {{tr.Prenoms}}
                  </vs-td>
                  <vs-td :data="tr.Adresse.mobile">
                    {{tr.Adresse.mobile}}
                  </vs-td>
                  <vs-td>
                    {{moneyFormatter(totalNet(tr.ventes.PrixMont1, tr.ventes.PrixVerG1, tr.ventes.PrixVerD1, tr.ventes.RemiseCom))}}
                  </vs-td>
                  <vs-td>
                    {{moneyFormatter(tr.ventes.RemiseCom)}}
                  </vs-td>
                  <vs-td>
                    {{moneyFormatter(calculParClient((tr.ventes.PrixMont1 ? tr.ventes.PrixMont1 : 0), (tr.ventes.PrixVerG1 ? tr.ventes.PrixVerG1 : 0), (tr.ventes.PrixVerD1 ? tr.ventes.PrixVerD1 : 0), (tr.ventes.RemiseCom ? tr.ventes.RemiseCom : 0), (tr.PlafForf ? tr.PlafForf : 0), (tr.ventes.TickForf ? tr.ventes.TickForf: 20)))}}
                  </vs-td>
                  <vs-td>
                    {{moneyFormatter(calculParAssurance((tr.ventes.PrixMont1 ? tr.ventes.PrixMont1 : 0), (tr.ventes.PrixVerG1 ? tr.ventes.PrixVerG1 : 0), (tr.ventes.PrixVerD1 ? tr.ventes.PrixVerD1 : 0), (tr.ventes.RemiseCom ? tr.ventes.RemiseCom : 0), (tr.PlafForf ? tr.PlafForf : 0), (tr.ventes.TickForf ? tr.ventes.TickForf: 20)))}}
                  </vs-td>
                  <vs-td>
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <!-- <feather-icon title="Réglèment commande" icon="SendIcon" svgClasses="w-5 h-5 mr-4 hover:text-primary stroke-current" @click.stop="reglementCmmd(tr._id, tr2.RefCmmd)" /> -->
                      <!--<feather-icon title="Voir" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showCommande(tr._id, tr.numVente)" />-->
                      <feather-icon title="Traiter" icon="SettingsIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="traitementCommande(tr, indextr)" />
                      <!-- <feather-icon title="Supprimer" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr._id, tr2.RefCmmd)" /> -->
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import * as lang from 'vuejs-datepicker/src/locale'
const VueHtml2pdf = () => import('vue-html2pdf')
export default {
  components: {
    Datepicker,
    VueHtml2pdf
  },
  data () {
    return {
      popupConsommerPlafon: false,
      popupTraitement: false,
      popupTraitementVerre: false,
      popupPrescription: false,
      selected:[],

      idOfficine: null,
      IdUser: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdPersonel : null,
      activeUserInfos: null,
      clientId: null,
      currentRefOrd: null,
      dateDebut: null,
      dateFin: null,
      assurance: null,

      lieu_edition: 'ABIDJAN LE',
      indexVente: null,
      NomCommercialVerre: null,
      RefVerD1: null,
      PrixVerD1: null,
      RefVerG1: null,
      PrixVerG1: null,
      QteVerD1: 1,
      QteVerG1: 1,
      SphVD: null,
      SphVG: null,
      CylVD: null,
      CylVG: null,
      AxeVD: null,
      AxeVG: null,
      AddVD: null,
      AddVG: null,
      IdMont1: null,
      PrixMont1: null,
      QteMonture1: 1,
      RemiseCom: null,
      plafon: null,

      prixPhotogray: 0,
      prixAntiReflet: 0,
      prixAntiRayure: 0,
      prixAutreTraitement: 0,

      RefPEC: null,
      IdSociete: null,
      IdAssurance: null,
      NomAssurance: null,
      NomSociete: null,
      RefCommande: null,
      NumPolice: null,
      Assurer: null,
      NumPatient: null,
      Patient: null,
      NumSalarie: null,
      Salarie: null,
      TickMont: null,
      TickVerr: null,
      PlafMont: null,
      PlafVerr: null,
      TickForf: null,
      PlafForf: null,
      QteMont: null,
      QteVerr: null,
      QtePair: null,

      DateVente: null,
      NumVente: null,
      NumFact: null,
      four_monture: null,
      monture: null,
      marque: null,
      entrepise: null,
      fournisseurVerre1: null,
      fournisseurVerre2: null,
      fournisseurMonture: null,
      matricule: null,
      four_verre: null,
      Idprescripteur: null,

      ajusterPrixMonture: true,
      ajusterPrixVerre: false,
      partMajorerClient: 0,
      tauxMajorations:
      [
        {taux: 5, valeur: '5%'},
        {taux: 10, valeur: '10%'},
        {taux: 15, valeur: '15%'},
        {taux: 20, valeur: '20%'},
        {taux: 25, valeur: '25%'},
        {taux: 30, valeur: '30%'},
        {taux: 35, valeur: '35%'},
        {taux: 40, valeur: '40%'},
        {taux: 45, valeur: '45%'},
        {taux: 50, valeur: '50%'}
      ],

      language: 'fr',
      languages: lang
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.libelle !== ''
    },
    clientsData () {
      return this.$store.state.client.clients
    },
    commandesNonTraiter () {
      return this.$store.state.facturation.commandes_non_traiter
    },
    officines () {
      return this.$store.state.officine.officines
    },
    prescripteurs () {
      return this.$store.state.prescripteur.prescripteurs
    },
    assuranceData () {
      return this.$store.state.assurance.assurances
    },
    fournisseur_verres_data () {
      return this.$store.state.verre.fournisseurs_verres
    },
    fournisseurs () {
      return this.$store.state.fournisseur.fournisseurs
    },
    Societes () {
      return this.$store.state.societe.societes
    }
  },

  methods: {
    generateReport () {

      this.$vs.loading({
        type: 'sound'
      })

      setTimeout(() => {
        this.$vs.loading.close()
      }, 10000)

      this.$refs.html2Pdf.generatePdf()

    },
    totalNet (PrixMont, PrixVerG, PrixVerD, RemiseCom) {
      return ((((+PrixMont ? +PrixMont : 0) + (+PrixVerG ? +PrixVerG : 0) + (+PrixVerD ? +PrixVerD : 0) + +(this.prixPhotogray ? this.prixPhotogray : 0) + +(this.prixAntiReflet ? this.prixAntiReflet : 0) + +(this.prixAntiRayure ? this.prixAntiRayure : 0) + +(this.prixAutreTraitement ? this.prixAutreTraitement : 0)) - (RemiseCom ? RemiseCom : 0)))
    },
    calculParClient (PrixMont, PrixVerG, PrixVerD, RemiseCom, plafon, ticket) {
      let partClient = null

      if (this.totalNet(PrixMont, PrixVerG, PrixVerD, RemiseCom) >= plafon) {
        partClient = this.totalNet(PrixMont, PrixVerG, PrixVerD, RemiseCom) - +plafon
      } else if (ticket <= 100) {
        partClient = Math.round(((this.totalNet(PrixMont, PrixVerG, PrixVerD, RemiseCom) * ticket) / 100))
      } else {
        partClient = ticket
      }

      return partClient
    },
    calculParAssurance (PrixMont, PrixVerG, PrixVerD, RemiseCom, plafon, ticket) {
      let PartAssu = null
      const factureNet = this.totalNet((PrixMont ? PrixMont : 0), (PrixVerG ? PrixVerG : 0), (PrixVerD ? PrixVerD : 0), (RemiseCom ? RemiseCom : 0))
      //par defaut le ticket moderateur = 20%
      const partClient = this.calculParClient((PrixMont ? PrixMont : 0), (PrixVerG ? PrixVerG : 0), (PrixVerD ? PrixVerD : 0), (RemiseCom ? RemiseCom : 0), (plafon ? plafon : 0), (ticket ? ticket : 20))
      if (factureNet >= plafon) {
        PartAssu = plafon
      } else {
        console.log('Total:', factureNet)
        console.log('Part client:', partClient)
        PartAssu = Math.round((+factureNet - +(partClient ? partClient : 0)))
      }
      return PartAssu
    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    reglementCmmd (clientId, RefCmmd) {
      this.$router.push(`/apps/caisse/${clientId}/${RefCmmd}`).catch((err) => { console.log(err) })
    },
    showCommande (clientId, RefCmmd) {
      this.$router.push(`/apps/commandeclient/detail/${clientId}/${RefCmmd}`).catch((err) => { console.log(err) })
    },
    traitementCommande (data, index) {
      this.indexVente = index
      const societes = this.$store.state.societe.societes
      const fournisseurVerres = this.$store.state.verre.fournisseurs_verres

      this.fournisseurVerre1 = fournisseurVerres.find((f) => f._id === (data ? data.ventes.IdFourVerD1 : null))
      this.fournisseurVerre2 = fournisseurVerres.find((f) => f._id === (data ? data.ventes.IdFourVerG1 : null))

      this.entrepise = societes.find((s) => s._id === data.IdSociete)

      this.IdClient = data ? data._id : null
      this.DateVente = data ? data.dateVente : null
      this.NumVente = data ? data.numVente : null
      this.NumFact = data ? data.numVente : null
      this.RefPEC = data ? data.RefPEC : null
      this.IdSociete = data ? data.IdSociete : null
      this.NomSociete = this.getSocieteById(this.IdSociete) ? this.getSocieteById(this.IdSociete).NomSociete : null
      this.IdAssurance = data ? data.assuranceId : null
      this.NomAssurance = this.getAssuranceName(this.IdAssurance) ? this.getAssuranceName(this.IdAssurance).Nom_Complet : null
      this.matricule = data ? data.NumPatient : null
      this.Patient = data ? data.Patient : null
      this.Assurer = data ? data.Assurer : null
      this.NumPatient = data ? data.NumPatient : null
      this.NumSalarie = data ? data.NumSalarie : null
      this.NumPolice = data ? data.NumPolice : null
      this.Idprescripteur = data ? data.ventes.Idprescripteur : null
      this.four_verre = data ? data.ventes.IdFourVerD1 : null
      this.plafon = data ? data.PlafForf : null
      this.TickForf = data ? data.TickForf : null
      this.TickMont = data ? data.TickMont : null
      this.TickVerr = data ? data.TickVerr : null
      this.PlafMont = data ? data.PlafMont : null
      this.PlafVerr = data ? data.PlafVerr : null
      this.NomCommercialVerre = data ? (data.ventes.RefVerD1 === data.ventes.RefVerG1 ? data.ventes.RefVerD1 : `${data.ventes.RefVerD1} / ${data.ventes.RefVerG1}`) : ''
      this.RefVerD1 = data ? data.ventes.RefVerD1 : null
      this.RefVerG1 = data ? data.ventes.RefVerG1 : null
      this.PrixVerD1 = data ? data.ventes.PrixVerD1 : null
      this.PrixVerG1 = data ? data.ventes.PrixVerG1 : null
      this.PrixMont1 = data ? data.ventes.PrixMont1 : null
      this.RemiseCom = data ? data.ventes.RemiseCom : null
      this.SphVD = data ? data.ventes.SphVD : null
      this.SphVG = data ? data.ventes.SphVG : null
      this.CylVD = data ? data.ventes.CylVD : null
      this.CylVG = data ? data.ventes.CylVG : null
      this.AxeVD = data ? data.ventes.AxeVD : null
      this.AxeVG = data ? data.ventes.AxeVG : null
      this.AddVD = data ? data.ventes.AddVD : null
      this.AddVG = data ? data.ventes.AddVG : null
      this.IdMont1 = data ? data.ventes.IdMont1 : null
      this.getArticleById(data.ventes.IdMont1)

      this.popupTraitement = true
    },
    /* conversion nombre en lettre */
    Unite (nombre) {
      let unite = ''
      switch (nombre) {
      case 0:
        unite = 'zéro'
        break
      case 1:
        unite = 'un'
        break
      case 2:
        unite = 'deux'
        break
      case 3:
        unite = 'trois'
        break
      case 4:
        unite = 'quatre'
        break
      case 5:
        unite = 'cinq'
        break
      case 6:
        unite = 'six'
        break
      case 7:
        unite = 'sept'
        break
      case 8:
        unite = 'huit'
        break
      case 9:
        unite = 'neuf'
        break
      }

      return unite
    },
    Dizaine (nombre) {
      let dizaine = ''
      switch (nombre) {
      case 10:
        dizaine = 'dix'
        break
      case 11:
        dizaine = 'onze'
        break
      case 12:
        dizaine = 'douze'
        break
      case 13:
        dizaine = 'treize'
        break
      case 14:
        dizaine = 'quatorze'
        break
      case 15:
        dizaine = 'quinze'
        break
      case 16:
        dizaine = 'seize'
        break
      case 17:
        dizaine = 'dix-sept'
        break
      case 18:
        dizaine = 'dix-huit'
        break
      case 19:
        dizaine = 'dix-neuf'
        break
      case 20:
        dizaine = 'vingt'
        break
      case 30:
        dizaine = 'trente'
        break
      case 40:
        dizaine = 'quarante'
        break
      case 50:
        dizaine = 'cinquante'
        break
      case 60:
        dizaine = 'soixante'
        break
      case 70:
        dizaine = 'soixante-dix'
        break
      case 80:
        dizaine = 'quatre-vingt'
        break
      case 90:
        dizaine = 'quatre-vingt-dix'
        break
      }
      return dizaine
    },
    NumberToLetter (nombre) {
      let quotient = 0, reste = 0
      let numberToLetter = ''
      //__________________________________

      if (nombre.toString().replace(/ /gi, '').length > 15) {
        return 'dépassement de capacité'
      }
      if (isNaN(nombre.toString().replace(/ /gi, ''))) {
        return 'Nombre non valide'
      }

      const nb = parseFloat(nombre.toString().replace(/ /gi, ''))
      if (Math.ceil(nb) !== nb) {
        return  'Nombre avec virgule non géré.'
      }

      const n = nb.toString().length
      switch (n) {
      case 1:
        numberToLetter = this.Unite(nb)
        break
      case 2:
        if (nb > 19) {
          quotient = Math.floor(nb / 10)
          reste = nb % 10
          if (nb < 71 || (nb > 79 && nb < 91)) {
            if (reste === 0) numberToLetter = this.Dizaine(quotient * 10)
            if (reste === 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-et-${  this.Unite(reste)}`
            if (reste > 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-${  this.Unite(reste)}`
          } else numberToLetter = `${this.Dizaine((quotient - 1) * 10)  }-${  this.Dizaine(10 + reste)}`
        } else numberToLetter = this.Dizaine(nb)
        break
      case 3:
        quotient = Math.floor(nb / 100)
        reste = nb % 100
        if (quotient === 1 && reste === 0) numberToLetter = 'cent'
        if (quotient === 1 && reste !== 0) numberToLetter = `cent ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.Unite(quotient)  } cents`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.Unite(quotient)  } cent ${  this.NumberToLetter(reste)}`
        break
      case 4 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 5 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 6 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 7:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 8:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 9:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 10:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 11:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 12:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 13:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 14:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 15:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      }//fin switch
      /*respect de l'accord de quatre-vingt*/
      if (numberToLetter.substr(numberToLetter.length - 'quatre-vingt'.length, 'quatre-vingt'.length) === 'quatre-vingt') numberToLetter = `${numberToLetter  }s`

      return numberToLetter.toUpperCase()
    },
    /* fin conversion nombre en lettre */
    getFournisseurVerreById (id) {
      const fournisseurVerres = this.$store.state.verre.fournisseurs_verres
      return fournisseurVerres ? fournisseurVerres.find((f) => f._id === id) : null
    },
    getFournisseurMontureById (id) {
      const four = this.$store.state.fournisseur.fournisseurs
      return four ? four.find((f) => f._id === id) : null
    },
    getMarqueName (id) {
      return this.$store.state.marque.marques ? this.$store.state.marque.marques.find((m) => m._id === id) : null
    },
    getAssuranceName (id) {
      return this.$store.state.assurance.assurances ? this.$store.state.assurance.assurances.find((a) => a._id === id) : null
    },
    getSocieteById (id) {
      const societes = this.$store.state.societe.societes

      return societes ? societes.find((s) => s._id === id) : null
    },
    editData (clientId, RefCmmd) {
      this.$router.push(`/apps/commandeclient/edit/${clientId}/${RefCmmd}`).catch((err) => { console.log(err) })
    },
    ConvertRemise () {
      if (this.RemiseCom <= 100) {
        this.RemiseCom = Math.round(((+this.PrixMont1 + +this.PrixVerG1 + +this.PrixVerD1 + +this.prixPhotogray + +this.prixAntiReflet + +this.prixAntiRayure + +this.prixAutreTraitement) * this.RemiseCom) / 100)
      }
    },
    CopyAllPrescriptionODToOG () {
      this.SphVG = this.SphVD
      this.CylVG = this.CylVD
      this.AxeVG = this.AxeVD
      this.AddVG = this.AddVD
    },
    CopySphVDToSphVG () {
      this.SphVG = this.SphVD
    },
    CopyCylVDToCylVG () {
      this.CylVG = this.CylVD
    },
    CopyAxeVDToAxeVG () {
      this.AxeVG = this.AxeVD
    },
    CopyAddVDToAddVG () {
      this.AddVG = this.AddVD
    },
    FormateSPHOD (sph) {
      //convert string to float and fix
      const Sph = parseFloat(sph).toFixed(2)

      this.SphVD = Math.sign(Sph) === 1 || Math.sign(Sph) === 0 ? `+${Sph}` : Sph
    },
    FormateSPHOG (sph) {
      //convert string to float and fix
      const Sph = parseFloat(sph).toFixed(2)

      this.SphVG = Math.sign(Sph) === 1 || Math.sign(Sph) === 0 ? `+${Sph}` : Sph
    },
    FormateCYLOD (cyl) {
      //convert string to float and fix
      const Cyl = parseFloat(cyl).toFixed(2)

      this.CylVD = Math.sign(Cyl) === 1 || Math.sign(Cyl) === 0 ? `+${Cyl}` : Cyl
    },
    FormateCYLOG (cyl) {
      //convert string to float and fix
      const Cyl = parseFloat(cyl).toFixed(2)

      this.CylVG = Math.sign(Cyl) === 1 || Math.sign(Cyl) === 0 ? `+${Cyl}` : Cyl
    },
    FormateAddVD (add) {
      //convert string to float and fix
      const Add = parseFloat(add).toFixed(2)

      this.AddVD = Math.sign(Add) === 1 || Math.sign(Add) === 0 ? `+${Add}` : Add
    },
    FormateAddVG (add) {
      //convert string to float and fix
      const Add = parseFloat(add).toFixed(2)

      this.AddVG = Math.sign(Add) === 1 || Math.sign(Add) === 0 ? `+${Add}` : Add
    },
    ConsommerPlafon (PrixMont, PrixVerG, PrixVerD, RemiseCom, plafon) {
      const ecart = +this.plafon - +this.factureNet
      const part_du_client = Math.round(((this.partMajorerClient * ecart) / 100))

      const factureNet = this.totalNet(PrixMont, PrixVerG, PrixVerD, RemiseCom)


      if (factureNet < plafon) {
        if (this.ajusterPrixMonture === true && this.ajusterPrixVerre === true) {
          const quart = Math.round((ecart / 4))
          const moitier = Math.round((ecart / 2))

          const pc_quart = Math.round((part_du_client / 4))
          const pc_moitier = Math.round((part_du_client / 2))

          this.PrixVerG1 = +this.PrixVerG1 + +quart + +pc_quart
          this.PrixVerD1 = +this.PrixVerD1 + +quart + +pc_quart
          this.PrixMont1 = this.PrixMont1 + moitier + +pc_moitier

          this.popupConsommerPlafon = false
        } else if (this.ajusterPrixMonture === true) {
          const part_du_client = Math.round(((this.partMajorerClient * ecart) / 100))
          this.PrixMont1 = this.PrixMont1 + ecart + +part_du_client

          this.popupConsommerPlafon = false

        } else if (this.ajusterPrixVerre === true) {
          const moitier = Math.round((ecart / 2))
          const pc_moitier = Math.round((part_du_client / 2))

          this.PrixVerG1 = +this.PrixVerG1 + +moitier + +pc_moitier
          this.PrixVerD1 = +this.PrixVerD1 + +moitier + +pc_moitier

          this.popupConsommerPlafon = false
        }

      } else {
        this.popupConsommerPlafon = false
        this.popupTraitement = false
        this.$vs.dialog({
          type: 'alert',
          color: 'danger',
          title: 'Alert',
          text: 'Impossible plafon déjà consommer',
          buttonAccept: 'true'
        })
      }


    },
    saveFacture () {
      const payload = {
        IdOfficine: this.idOfficine,
        IdUser: this.IdUser,
        Idprescripteur: this.Idprescripteur,
        IdClient: this.IdClient,
        RefPEC: this.RefPEC,
        NumVente: this.NumVente,
        NumFact: this.NumFact,
        DateVente: new Date(this.DateVente).toISOString().split('T')[0],
        matricule: this.matricule,
        patient: this.Patient,
        NumPatient: this.NumPatient,
        NumPolice: this.NumPatient,
        Assurer: this.Assurer,
        NumSalarie: this.NumSalarie,
        IdAssurance: this.IdAssurance,
        NomAssurance: this.getAssuranceName(this.IdAssurance) ? this.getAssuranceName(this.IdAssurance).Nom_Complet : null,
        IdSociete: this.IdSociete,
        NomSociete: this.getSocieteById(this.IdSociete) ? this.getSocieteById(this.IdSociete).NomSociete : null,
        PlafForf: this.plafon,
        PlafVerr: this.PlafVerr,
        PlafMont: this.PlafMont,
        TickForf: this.TickForf,
        TickMont: this.TickMont,
        TickVerr: this.TickVerr,
        SphVD: this.SphVD,
        SphVG: this.SphVG,
        CylVD: this.CylVD,
        CylVG: this.CylVG,
        AxeVD: this.AxeVD,
        AxeVG: this.AxeVG,
        AddVD: this.AddVD,
        AddVG: this.AddVG,
        IdMont: this.IdMont1,
        QteMont: this.QteMonture1,
        marqueMont: this.monture ? (this.getMarqueName(this.monture.IdMarque) ? this.getMarqueName(this.monture.IdMarque).Libelle_marq : '') : '',
        refMont: this.monture ? this.monture.Etiquette : '',
        couleurMont: this.monture ? this.monture.Couleur : '',
        tailleMont: this.monture ? `${this.monture.Taille[0].Longeur}/${this.monture.Taille[0].Hauteur}/${this.monture.Taille[0].Nez}` : '',
        PrixMont: this.PrixMont1,
        RefVerG: this.RefVerG1,
        PrixVerG: this.PrixVerG1,
        QteVerG: this.QteVerG1,
        RefVerD: this.RefVerD1,
        PrixVerD: this.PrixVerD1,
        QteVerD: this.QteVerD1,
        RemiseCom: this.RemiseCom,
        IdfourVerre: this.four_verre,
        NomCommercialVerre: this.NomCommercialVerre,
        NomfourVerre: this.getFournisseurVerreById(this.four_verre) ? this.getFournisseurVerreById(this.four_verre).Fournisseur : '',
        IdfourMonture: this.four_monture,
        NomfourMonture: this.getFournisseurMontureById(this.four_monture) ? this.getFournisseurMontureById(this.four_monture).Nom : null,
        prixPhotogray: this.prixPhotogray,
        prixAntiReflet: this.prixAntiReflet,
        prixAntiRayure: this.prixAntiRayure,
        prixAutreTraitement: this.prixAutreTraitement,
        indexVente: this.indexVente

      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('facturation/traitementFacture', payload)
        .then(() => {
          this.popupTraitement = false
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })

    },
    confirmDeleteRecord (clientId, RefCmmd) {
      this.clientId = clientId
      this.currentRefOrd = RefCmmd

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Ordonnance suppression',
        text: `Vous allez supprimer la commande "${RefCmmd}"`,
        acceptText: 'Supprimer',
        accept: this.deleteData
      })
    },
    deleteData () {
      const payload = {
        clientId: this.clientId,
        refOrd: this.currentRefOrd
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('client/removeOrdonnance', payload)
        .then(() => {
          this.$vs.notify({
            title: 'Ordonnance Supprimer',
            text: 'supprimer!',
            iconPack: 'feather',
            icon: 'icon-trash',
            color: 'danger'
          })
          this.showDeleteSuccess()
          this.$router.push(`/apps/client/client-view/${this.clientId}`).catch((err) => { console.log(err) })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'Suppression ordonnance',
        text: 'Supprimer'
      })
    },
    getPrescripteurName (id) {
      const prescripteur = this.prescripteurs.find((p) => p._id === id) ? this.prescripteurs.find((p) => p._id === id) : ''
      return `${prescripteur.NomPresc} ${prescripteur.PrenomPresc}`
    },
    getPrescripteurs () {
      this.$store.dispatch('prescripteur/getPrescripteurs')
        .then(() => {

        })
        .catch(err => { console.log(err) })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    CurrentOfficineSelectClient () {
      this.getCommandeNonTraiter(this.idOfficine)
    },
    totalVerreProposition (prixVer1, prixVer2) {
      return (+prixVer1 + +prixVer2) + +this.prixPhotogray + +this.prixAntiReflet + +this.prixAntiRayure + +this.prixAutreTraitement
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      /* this.idOfficine = this.activeUserInfos.IdOfficine

      this.getCommandeNonTraiter(this.idOfficine) */
    },
    getArticleById (id) {
      this.$store.dispatch('stock/getArticleById', id)
        .then((response) => {
          this.monture = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    findVenteNonTraiterByAssurance () {
      const payload = {
        idOfficine: this.idOfficine,
        dateDebut: new Date(this.dateDebut).toISOString().split('T')[0],
        dateFin: new Date(this.dateFin).toISOString().split('T')[0],
        assurance: this.assurance
      }
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#button-with-loading',
        type: 'sound',
        scale: 0.45
      })

      this.$store.dispatch('facturation/findVenteNonTraiterByAssurance', payload)
        .then(() => {
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })
    },
    getCommandeNonTraiter (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('facturation/getCommandeNonTraiter', id)
        .then((response) => {
          this.$vs.loading.close()
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    getAssurances () {
      this.$store.dispatch('assurance/getAssurances')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getSocietes () {
      this.$store.dispatch('societe/getSocietes')
        .then(() => {
        })
        .catch((err) => { console.log(err) })
    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getFournisseurs () {
      this.$store.dispatch('fournisseur/getFournisseurs').catch(err => { console.error(err) })
    },
    getFournisseursVerres () {
      this.$store.dispatch('verre/getFournisseursVerres')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })

    }
  },
  created () {
    this.activeUserInfo()
    this.getOfficines()
    this.getAssurances()
    this.getSocietes()
    this.getMarques()
    this.getFournisseurs()
    this.getFournisseursVerres()
    this.getPrescripteurs()
  }
}
</script>

<style scoped>
.customtable, .customth, .customtd {
  border: 0.5px solid black;
  border-collapse: collapse;
  /* font-family: 'Times New Roman'; */
  font-size: 12px;
}

.customtd {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
}
</style>

